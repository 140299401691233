#particles-js {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}

.green {
  color: #1fab89;
  font-weight: bold;
}

.yellow {
  color: #ffd460;
  font-weight: bold;
}

.orange {
  color: #f07b3f;
  font-weight: bold;
}

.red {
  color: #fa4659;
  font-weight: bold;
}

.other {
  color: #52616b;
  font-weight: bold;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  color: #404040;
  background: white;
  font-size: 1rem;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  max-width: 100%;
  padding: 0 1rem;
  margin-left: auto;
  margin-right: auto;
}

[type=color], [type=date], [type=datetime], [type=datetime-local], [type=email], [type=month], [type=number], [type=password], [type=search], [type=tel], [type=text], [type=url], [type=week], [type=time], select, textarea {
  display: block;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 0.75rem;
  outline: none;
  background: transparent;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 100%;
  max-width: 100%;
  line-height: 1;
}