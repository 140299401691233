.logo,
h1,
h2,
h3,
h4,
h5 {
    margin: 1.5rem 0;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
    line-height: 1.2;
    color: white;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child) {
    margin: 1.5rem 0;
}

h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child) {
    margin-top: 2rem;
}

h1 {
    font-size: 1.75rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1.25rem;
}

h4 {
    font-size: 1.1rem;
}

h5 {
    font-size: 1rem;
}

@media (min-width: 600px) {
    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child) {
        margin-top: 2.5rem;
    }
    h1 {
        font-size: 2.25rem;
    }
    h2 {
        font-size: 2rem;
    }
    h3 {
        font-size: 1.75rem;
    }
    h4 {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 1.25rem;
    }
}