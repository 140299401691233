.button {
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

.button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

.button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

.button, a.button, button, [type=submit], [type=reset], [type=button] {
    -webkit-appearance: none;
    display: inline-block;
    border: 1px solid #005691;
    border-radius: 4px;
    background: #005691;
    color: #ffffff;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    text-transform: none;
    padding: 0.75rem 1.25rem;
    margin: 0 0 0.5rem 0;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;
}

.button:hover, a.button:hover, button:hover, [type=submit]:hover, [type=reset]:hover, [type=button]:hover {
    border: 1px solid #0250bc;
    background: #0250bc;
    color: #ffffff;
    text-decoration: none;
}

.button:focus, .button:active, a.button:focus, a.button:active, button:focus, button:active, [type=submit]:focus, [type=submit]:active, [type=reset]:focus, [type=reset]:active, [type=button]:focus, [type=button]:active {
    border: 1px solid #0250bc;
    background: #0250bc;
    color: #ffffff;
    text-decoration: none;
}

.button::-moz-focus-inner, a.button::-moz-focus-inner, button::-moz-focus-inner, [type=submit]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=button]::-moz-focus-inner {
    border: 0;
    padding: 0;
}

.accent-button,
a.accent-button {
    color: #ffffff;
    border: 1px solid #29de7d;
    background: #29de7d;
}

.accent-button:hover, .accent-button:focus, .accent-button:active,
a.accent-button:hover,
a.accent-button:focus,
a.accent-button:active {
    color: #ffffff;
    border: 1px solid #1cb864;
    background: #1cb864;
}

.muted-button,
a.muted-button {
    background: transparent;
    border: 1px solid #cdcdcd;
    color: #4e4e4e;
}

.muted-button:hover, .muted-button:focus, .muted-button:active,
a.muted-button:hover,
a.muted-button:focus,
a.muted-button:active {
    color: #4e4e4e;
    border: 1px solid #818181;
    background: transparent;
}

.round-button,
a.round-button {
    border-radius: 40px;
}

.square-button,
a.square-button {
    border-radius: 0;
}

.full-button,
a.full-button {
    display: block;
    width: 100%;
}