.table {
    font-size: 100%;
    line-height: 1.15;
    margin: 1rem 0 1.5rem 0;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    max-width: 100%;
    background-color: rgba(255,255,255,0.8);
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

.tableC {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
}

.csvLink {
    margin: 0;
}

.results {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.results span {
    margin-top: 0.6rem;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: white;
}

.greenLine {
    border-bottom: 3px solid #1fab89;
}

.yellowLine {
    border-bottom: 3px solid #ffd460;
}

.orangeLine {
    border-bottom: 3px solid #f07b3f;
}

.redLine {
    border-bottom: 3px solid #fa4659;
}

.otherLine {
    border-bottom: 3px solid #52616b;
}

thead th {
    border-bottom: 2px solid #dedede;
}

tfoot th {
    border-top: 2px solid #dedede;
}

td {
    border-bottom: 1px solid #dedede;
}

th,
td {
    text-align: left;
    padding: 0.5rem;
}

caption {
    padding: 1rem 0;
    caption-side: bottom;
    color: #ababab;
}

.striped-table tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
}